<template>
  <div>
    <h1 class="text-h4">Управление пользователями</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-card-title>
        Пользователи
        <v-spacer></v-spacer>
        <v-text-field
          v-model="filter.search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          clearable
          @input="onInputFilter"
        ></v-text-field>
        <v-btn icon class="ml-2 mt-1" @click="toggleAdvancedSearch">
          <v-icon>{{ showAdvancedSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-title>
      <transition name="slide">
        <v-card-text v-show="showAdvancedSearch">
          <v-combobox
            v-model="filter.status"
            label="Статус пользователя"
            hint="Укажите необходимый статус пользователя"
            persistent-hint
            chips
            clearable
            :items="statusOptions"
            multiple
            :loading="loading"
            @input="onInputFilter"
          ></v-combobox>
          <v-combobox
            v-model="filter.roles"
            label="Роли пользователя"
            hint="Укажите необходимые роли пользователя"
            persistent-hint
            chips
            clearable
            :items="roleOptions"
            multiple
            :loading="loading"
            @input="onInputFilter"
          ></v-combobox>
          <v-switch
            v-model="filter.withDeleted"
            label="Показать удаленные"
            inset
            @change="onInputFilter"
          ></v-switch>
        </v-card-text>
      </transition>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="success"
              :disabled="loading"
              v-bind="attrs"
              v-on="on"
              @click="createUserAction"
            >
              <v-icon>mdi-account-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Добавить нового пользователя</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="error"
              :disabled="loading || !hasSelected"
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="deleteUserSelectionAction"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Удалить пользователей</span>
        </v-tooltip>
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-can:access="{type:'acl', id:'Admin'}"
                  :disabled="loading || !hasSelected"
                  v-bind="attrs"
                  fab
                  class="ml-1"
                  v-on="{ ...tooltip, ...menu}"
                >
                  <v-icon>mdi-list-status</v-icon>
                </v-btn>

              </template>
              <span>Изменить статус</span>
            </v-tooltip>

          </template>
          <v-list>
            <v-list-item @click="changeStatusSelectionAction('Active')">
              <v-list-item-title>Активный</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeStatusSelectionAction('Blocked')">
              <v-list-item-title>Заблокирован</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeStatusSelectionAction('Pending')">
              <v-list-item-title>В ожидании</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
      <v-data-table
        v-model="selectedItems"
        :loading="loading"
        show-select
        :headers="computedHeaders"
        :items="items"
        :options.sync="table.options"
        :server-items-length="itemsTotalCount"
        :footer-props="table.footerProps"
        :item-class="tableItemClass"
      >
        <template v-slot:footer.page-text="{pageStart,pageStop,itemsLength}">
          <span v-if="itemsLength>0">{{ pageStart }}-{{ pageStop }} из {{ itemsLength }}</span>
        </template>

        <template v-slot:item.id="{ item }">
          {{ item.id | shortenId }}
        </template>

        <template v-slot:item.hasImage="{ item }">
          <v-avatar size="40" class="my-1">
            <img :src="item.id | avatarUrl('thumb', item.hasImage)"/>
          </v-avatar>
        </template>

        <template v-slot:item.email="{ item }">
          <a
            v-if="!item.meta.isDeleted"
            href="#"
            class="text-decoration-none"
            @click.stop.prevent="editUserAction(item)"
          >{{ item.email }}</a>
          <span v-else>{{ item.email }}</span>
        </template>

        <template v-slot:item.status="{ item }">
          <v-icon v-if="item.status==='Active'" small color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-else-if="item.status==='Blocked'" small color="warning">
            mdi-pause-circle
          </v-icon>
          <v-icon v-else-if="item.status==='Pending'" small>
            mdi-circle-outline
          </v-icon>
        </template>

        <template v-slot:item.meta.createdAt="{ item }">
          {{ item.meta.createdAt | formatDateTime }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="!item.meta.isDeleted"
            v-can:access="{type:'acl', id:'Manager'}"
            small
            class="mr-2"
            @click="editUserAction(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="!item.meta.isDeleted"
            v-can:access="{type:'acl', id:'Admin'}"
            small
            @click="deleteUserAction(item)"
          >mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </v-card>

    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление данных</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteUsers">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Page from '@/model/Page'
import { Asc, Desc } from '@/model/SortOrder'
import _ from 'lodash'
import UserFilterInput from '@/model/user/UserFilterInput'
import ToastService from '@/service/ToastService'

export default {
  ...dashboardPageCommonAttributes,
  name: 'UsersListPage',
  data: function () {
    return {
      loading: false,
      busy: false,
      table: {
        options: {},
        footerProps: {
          itemsPerPageOptions: [5, 10, 15, 30, 50],
          itemsPerPageText: 'Строк на странице:',
          // showCurrentPage:true,
          showFirstLastPage: true
        }
      },
      headers: [
        { text: 'Id', align: 'left', value: 'id' },
        { text: '', value: 'hasImage' },
        { text: 'Email', value: 'email' },
        { text: 'Имя', value: 'fullName' },
        { text: 'Роль', value: 'role' },
        { text: 'Статус', value: 'status' },
        { text: 'Создан', value: 'meta.createdAt' },
        { text: 'Действия', value: 'actions', sortable: false, align: 'right' }
      ],
      filter: {
        search: '',
        status: [],
        roles: [],
        withDeleted: false
      },
      selectedItems: [],
      items: [],
      itemsTotalCount: 0,
      statusOptions: [],
      roleOptions: [],

      deleteDialog: {
        show: false,
        value: []
      }
    }
  },
  computed: {
    ...mapState('users',['showAdvancedSearch']),
    ...mapGetters('users', ['userList', 'userListTotalCount']),
    hasSelected() {
      return this.selectedItems.length > 0
    },
    computedHeaders() {
      const hasActionsPermission = this.$can('access', { type: 'acl', id: 'Admin' })

      if (hasActionsPermission) {
        return this.headers
      }

      return this.headers.filter((h) => h.value !== 'actions')
    }
  },
  watch: {
    'table.options': {
      handler() {
        this.loading = true
        this.loadTable().then(() => {
          this.items = this.userList
          this.itemsTotalCount = this.userListTotalCount
          this.loading = false
        })
      },
      deep: true
    }
  },
  created() {
    const p = this.$store.state.users.page
    const o = {
      page: p.page + 1,
      itemsPerPage: p.size,
      sortBy: p.sort.map((e) => e.field),
      sortDesc: p.sort.map((e) => e.order === Desc)
    }

    this.table.options = o

    const { filter } = this.$store.state.users

    this.filter.search = filter?.search ?? ''

    const statusOptions = [
      { text: 'Активный', value: 'Active' },
      { text: 'Заблокирован', value: 'Blocked' },
      { text: 'В ожидании', value: 'Pending' }
    ]

    this.statusOptions = statusOptions
    this.filter.status = filter.statuses.map((e) => statusOptions.find((o) => o.value === e))

    const roleOptions = [
      { text: 'Пользователь', value: 'User' },
      { text: 'Менеджер', value: 'Manager' },
      { text: 'Админ', value: 'Admin' }
    ]

    this.roleOptions = roleOptions
    this.filter.roles = filter.roles.map((e) => roleOptions.find((o) => o.value === e))

    this.filter.withDeleted = filter.withDeleted
  },
  methods: {
    tableItemClass(item) {
      if (item.meta.isDeleted) {
        return ['deleted']
      }

      return ''
    },
    ...mapMutations('users', {
      setPage: 'SET_PAGE',
      setFilter: 'SET_FILTER',
      toggleAdvancedSearch: 'TOGGLE_ADVANCED_SEARCH'
    }),
    ...mapActions('users', ['fetchUsers', 'userDelete', 'userChangeStatus']),
    loadTable() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.table.options

      const sort = []

      sortBy.forEach((field, index) => {
        sort.push({
          field,
          order: (sortDesc[index]) ? Desc : Asc
        })
      })

      const p = Page.create({
        page: page - 1,
        size: itemsPerPage,
        sort
      })

      this.setPage({ page: p })

      return this.fetchUsers()
    },
    onInputFilter: _.debounce(function () {
      // const filter = filter
      const filterStatus = this.filter.status.map((element) => {
        if (typeof element === 'object') {
          return element.value
        } else {
          return element
        }
      })

      const filterRoles = this.filter.roles.map((element) => {
        if (typeof element === 'object') {
          return element.value
        } else {
          return element
        }
      })

      const filter = UserFilterInput.create({
        ...this.filter,
        statuses: filterStatus,
        roles: filterRoles
      })

      this.table.options.page = 1

      this.setFilter({ filter })
      this.loading = true
      this.loadTable().then(() => {
        this.items = this.userList
        this.itemsTotalCount = this.userListTotalCount
        this.loading = false
      })
    }, 500),
    createUserAction() {
      this.$router.push({ name: 'users.create' })
    },
    editUserAction(item) {
      this.$router.push({ name: 'users.edit', params: { userId: item.id } })
    },
    deleteUserAction(item) {
      this.deleteDialog.value = [item.id]
      this.deleteDialog.show = true
    },
    deleteUserSelectionAction() {
      this.deleteDialog.value = this.selectedItems.map((item) => item.id)
      this.deleteDialog.show = true
    },

    deleteUsers() {
      this.deleteDialog.show = false
      const ids = this.deleteDialog.value

      this.loading = true
      this.userDelete({ ids })
        .then((ids) => {
          ToastService.success('Данные сохранены')
          this.selectedItems = []

          return this.loadTable()
        })
        .then(() => {
          this.items = this.userList
          this.itemsTotalCount = this.userListTotalCount
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.loading = false
          this.idsToDelete = []
        })
    },

    changeStatusSelectionAction(status) {
      const ids = this.selectedItems.map((item) => item.id)

      this.loading = true

      this.userChangeStatus({ ids, status })
        .then((ids) => {
          ToastService.success('Данные сохранены')
          this.selectedItems = []

          return this.loadTable()
        })
        .then(() => {
          this.items = this.userList
          this.itemsTotalCount = this.userListTotalCount
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Список пользователей'
      }
    }
  }
}
</script>

<style>
tr.deleted {
  background-color: lightpink;
}

tr.deleted:hover {
  background-color: lightcoral !important;
}

.slide-enter, .slide-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease;
}
</style>
